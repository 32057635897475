<template>
	<DxTabPanel>
		<DxItem title="Application" icon="floppy">
			<div>
				<DxPopup v-model:visible="photoDisplayPopup" :hide-on-outside-click="true" :show-close-button="true">
					<img v-if="attachmentPhoto" width="99%" height="99%" :src="appInfo.storageURL + '/' + attachmentPhoto.store" style="object-fit:contain;" />
				</DxPopup>
				<div style="border: 1px solid black; margin: 20px; display:inline-block;">
					<a v-if="attachmentPhoto" href="#" @click.prevent="photoDisplayPopup = true">
						<img :src="appInfo.storageURL + '/' + attachmentPhoto.store" width="200" />
					</a>
					<div v-else class="applicant-no-photo">
						<br>
						<i class="dx-icon dx-icon-image" />
						<br><br>
					</div>
				</div>
				<div style="margin: 20px; display:inline-block;">
					<div>
						<DxLinearGauge :value="applicantStatusNumber">
							<DxScale :start-value="0" :end-value="10" :tick-interval="1">
								<DxTick :visible="false" />
								<DxLabel :visible="false" />
							</DxScale>
							<DxRangeContainer>
								<DxRange :start-value="0" :end-value="3" color="#92000A" />
								<DxRange :start-value="3" :end-value="7" color="#E6E200" />
								<DxRange :start-value="7" :end-value="10" color="#77DD77" />
							</DxRangeContainer>
							<DxValueIndicator type="textCloud" color="#f05b41">
								<DxText :customize-text="customizeStatusLabel" />
							</DxValueIndicator>
							<DxAnimation :duration="500">
							</DxAnimation>
						</DxLinearGauge>
					</div>
					<div v-if="props.applicant.deleted_at" style="padding: 5px; background: var(--dx-color-danger); color: white; border-radius: 5px;">
						<span class="fieldName">Deleted at:</span> {{ formatDatePH(props.applicant.deleted_at) }}
					</div>
					<div :style="{ textDecoration: props.applicant.deleted_at ? 'line-through' : 'none' }">
						<span class="fieldName">External ID:</span> {{ props.applicant.external_id }}
					</div>
					<div>
						<span class="fieldName">Email:</span> <a :href="'mailto:' + props.applicant.email">{{ props.applicant.email }}</a>
					</div>
					<div>
						<span class="fieldName">Facebook:</span> <a :href="'https://www.facebook.com/' + props.applicant.facebook" target="_blank">{{ props.applicant.facebook }}</a>
					</div>
					<div>
						<span class="fieldName">Mobile:</span> <a :href="'tel:' + props.applicant.mobile">{{ props.applicant.mobile }}</a>
					</div>
					<div>
						<span class="fieldName">Actions:</span>
						<span>
							<dx-button type="default" styling-mode="contained" height="24px" icon="edit" text="Edit" @click="emit('triggerEdit')" style="margin-right: 5px;" />
							<dx-button type="danger" styling-mode="contained" height="24px" icon="trash" text="Delete" @click="emit('triggerDelete')" v-if="!props.applicant.deleted_at" />
							<dx-button type="normal" styling-mode="contained" height="24px" icon="undo" text="Restore" @click="emit('triggerRestore')" v-if="props.applicant.deleted_at" />
						</span>
					</div>
				</div>
			</div>
		</DxItem>
		<DxItem title="Full View" icon="columnfield">
			<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;">
				<div class="applicant-details-grid">
					<div class="section-header">Basic Information</div><div class="section-spacer"></div>
					<template v-if="props.applicant.deleted_at">
						<div class="field-name" style="padding: 5px; background: var(--dx-color-danger); color: white; border-radius: 5px;">Deleted At:</div><div style="padding: 5px; background: var(--dx-color-danger); color: white; border-radius: 5px;">{{ formatDatePH(props.applicant.deleted_at) }}</div>
					</template>
					<div class="field-name">ID:</div><div>{{ applicant.id }}</div>
					<div class="field-name">Full Name:</div>
					<div>
						{{ applicant.first_name }} {{ applicant.middle_name }} {{ applicant.last_name }}
						<DxButton style="margin-top:-2px;" styling-mode="text" type="default" :height="20" :width="20" :icon="toggleNameDetails ? 'isblank' : 'info'" @click="toggleNameDetails = !toggleNameDetails"
						/>
					</div>
					<template v-if="toggleNameDetails">
						<div class="field-name">First Name:</div><div>{{ applicant.first_name }}</div>
						<div class="field-name">Middle Name:</div><div>{{ applicant.middle_name }}</div>
						<div class="field-name">Last Name:</div><div>{{ applicant.last_name }}</div>
					</template>

					<div class="section-header">Academic Information</div><div class="section-spacer"></div>
					<div class="field-name">Branch:</div><div>{{ applicant.branch }}</div>
					<div class="field-name">School:</div><div>{{ applicant.school }}</div>
					<div class="field-name">Course:</div><div>{{ applicant.course }}</div>
					<div class="field-name">Review Program:</div><div>{{ applicant.review_program }}</div>
					<div class="field-name">Board Exam Date:</div><div>{{ applicant.board_exam_date }}</div>

					<div class="section-header">Contact Information</div><div class="section-spacer"></div>
					<div class="field-name">Mobile:</div><div><a :href="'tel:' + applicant.mobile">{{ applicant.mobile }}</a></div>
					<div class="field-name">Email:</div><div><a :href="'mailto:' + applicant.email">{{ applicant.email }}</a></div>
					<div class="field-name">Facebook:</div><div><a :href="'https://www.facebook.com/' + applicant.facebook" target="_blank">{{ applicant.facebook }}</a></div>
					<div class="field-name">Address:</div><div>{{ applicant.address }}</div>

					<div class="section-header">Emergency Contact</div><div class="section-spacer"></div>
					<div class="field-name">Name:</div><div>{{ applicant.emergency_name }}</div>
					<div class="field-name">Relationship:</div><div>{{ applicant.emergency_relationship }}</div>
					<div class="field-name">Phone:</div><div>{{ applicant.emergency_phone }}</div>
					<div class="field-name">Address:</div><div>{{ applicant.emergency_address }}</div>
					
					<div class="section-header">System Information</div><div class="section-spacer"></div>
					<div class="field-name">Created At:</div><div>{{ formatDatePH(applicant.created_at) }}</div>
					<div class="field-name">Updated At:</div><div>{{ formatDatePH(applicant.updated_at) }}</div>
					<div class="field-name">User ID:</div><div>{{ applicant.user_id }}</div>
					<div class="field-name">Survey ID:</div><div>{{ applicant.survey_id }}</div>
					<div class="field-name">External ID:</div><div>{{ applicant.external_id }}</div>
				</div>
			</div>
		</DxItem>
		<DxItem title="Notes" icon="comment">
			<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;white-space:pre-line;">
				<DxTextArea ref="notesComponent" :value="applicantNotes" :width="600" styling-mode="outlined" :auto-resize-enabled="true" :min-height="140" :max-height="500" placeholder="No notes for this applicant yet." @value-changed="applicantNotes = $event.value" />
				<DxButton style="margin-top: 2px;" type="default" styling-mode="contained" :width="600" icon="edit" text="Save notes" @click="saveNotes" />
			</div>
		</DxItem>
		<DxItem title="History" icon="columnfield">
			<div style="margin:20px; padding:20px; max-width:800px;">
				<div class="applicant-history-item" v-if="props.applicant.deleted_at">
					<span class="applicant-history-date">{{ formatDatePH(props.applicant.deleted_at) }}</span>
					<span class="applicant-history-text">Application deleted by staff</span>
				</div>
				<div class="applicant-history-item" v-if="props.applicant.updated_at != props.applicant.created_at">
					<span class="applicant-history-date">{{ formatDatePH(props.applicant.updated_at) }}</span>
					<span class="applicant-history-text">Application last updated</span>
				</div>
				<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
					<span class="applicant-history-date">{{ formatDatePH(props.applicant.created_at) }}</span>
					<span class="applicant-history-text">Application created</span>
				</div>
			</div>
		</DxItem>
	</DxTabPanel>
</template>

<style lang="scss">
.applicant-no-photo {
	width:200px;
	background: var(--dx-color-border);
	text-align: center;
}
.applicant-no-photo .dx-icon {
	text-decoration: line-through;
	text-decoration-color: var(--dx-color-primary);
}
.applicant-history-item {
	border: 1px solid gray;
	padding: 10px;
	margin-bottom: 10px;
	background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
}
.applicant-history-date {
	font-weight: bold;
	margin-right: 10px;
	color: #555;
}
.applicant-history-text {
	color: var(--dx-color-text);
}
.applicant-details-grid {
	display: grid;
	grid-template-columns: 200px 1fr;
	gap: 12px;
	align-items: baseline;

	.field-name {
		font-weight: 600;
		color: #666;
	}
	.section-header {
		grid-column: 1 / -1;
		font-weight: 700;
		font-size: 1.1em;
		color: #333;
		margin-top: 20px;
		padding-bottom: 5px;
		border-bottom: 2px solid #eee;
	}
	.section-header:first-child {
		margin-top: 0;
	}
	.section-spacer {
		display: none;
	}
}
</style>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits, ref, watch, inject, computed } from "vue";
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import { DxButton } from 'devextreme-vue/button';
import { DxLinearGauge, DxValueIndicator, DxRangeContainer, DxRange, DxScale, DxTick, DxLabel, DxText, DxAnimation } from 'devextreme-vue/linear-gauge';
import { DxPopup } from 'devextreme-vue/popup';
import { DxTextArea } from 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import appInfo from "../app-info";

const $http = inject('$http');
const props = defineProps(['applicant']);
const emit = defineEmits(['triggerEdit', 'triggerDelete', 'triggerRestore']);
const notesComponent = ref(null);
const applicantStatusNumber = computed(() => {
	if (!props.applicant) return 0;
	if (props.applicant.deleted_at) return 1;
	return (props.applicant.updated_at != props.applicant.created_at) ? 4 : 3;
});
const attachmentPhotos = props.applicant.attachments && Array.isArray(props.applicant.attachments) && props.applicant.attachments.length > 0 ?
	props.applicant.attachments.filter(a => a.filemime.toLowerCase().startsWith('image')) : null;
const attachmentPhoto = attachmentPhotos && attachmentPhotos.length >= 1 ? attachmentPhotos[0] : null;

const photoDisplayPopup = ref(false);
const toggleNameDetails = ref(false);

const applicantNotes = ref('');
watch(props, () => {
	applicantNotes.value = props.applicant.notes;
}, { immediate: true });

const customizeStatusLabel = function(labelData) {
	switch (labelData.value) {
		case 0:		return "Imported data";
		case 1:		return "Application deleted by staff";
		case 2:		return "";
		case 3:		return "Application submitted";
		case 4:		return "Application updated";
		case 5:		return "";
		case 6:		return "";
		case 7:		return "";
		case 8:		return "";
		case 9:		return "";
		case 10:	return "";
		default:	return "";
	}
}

// NB: Intl formatting for fil-PH or en-PH doesn't work as expected in many browsers.
const formatDatePH = (zuluTime) => {
	try {
		const date = new Date(zuluTime);
		if (isNaN(date)) return 'Invalid date';
		
		const phDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Manila' }));
		
		const year = phDate.getFullYear();
		const month = String(phDate.getMonth() + 1).padStart(2, '0');
		const day = String(phDate.getDate()).padStart(2, '0');
		const hours = String(phDate.getHours()).padStart(2, '0');
		const minutes = String(phDate.getMinutes()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}`;
	} catch (error) {
		console.error('Error formatting date:', error);
		return 'Invalid date';
	}
};

const saveNotes = () => {
	$http.post(`/application/${props.applicant.id}/setNotes`, { notes: applicantNotes.value }).then(response => {
		if (response.status !== 200) {
			console.error('Failed to update application record (error code ' + response.status + ')');
			notify({ message: 'Failed to update application record (error code ' + response.status + ')', position: 'top right' }, "error", 3000);
		}
		notify({ message: "Notes saved successfully!", position: 'top right' }, "success", 3000);
		return response.data;
	}).catch(error => {
		notify({ message: "Error updating application record!", position: 'top right' }, "error", 3000);
		console.error('Error updating application record:', error);
		throw error;
	});
};

</script>