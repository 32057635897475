<template>
	<div>
		<h2 class="content-block">Application Management</h2>

		<dx-data-grid ref="datagridComponent" style="width:100%;" class="dx-card wide-card"
			:data-source="dataSourceConfig"
			:focused-row-index="0"
			:show-borders="false"
			:focused-row-enabled="true" :focusedRowIndex="-1"
			:column-auto-width="false"
			:column-hiding-enabled="true"
			:allowColumnResizing="true" columnResizingMode="widget"
			:keyboard-navigation="{ enabled: false }"
			@exporting="onExporting"
		>
			<dx-state-storing :enabled="false" type="localStorage" storage-key="manage-applicants" />
			<dx-column-chooser :enabled="true" mode="select" :searchEnabled="true" height="400px">
				<dx-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />
			</dx-column-chooser>
			<dx-paging :page-size="defaultPagerSize" />
			<dx-pager :show-page-size-selector="true" :show-info="true" :allowed-page-sizes="[5, 10, 15, 20, 50]" />
			<dx-filter-row :visible="true" />
			<dx-header-filter :visible="true" />
			<dx-search-panel :visible="true" width="300px" />
			<dx-toolbar>
				<dx-item location="before" name="groupBySwitcher">
					<dx-button icon="hierarchy" hint="Group by school"
						styling-mode="contained" :type="groupBySchool ? 'success' : 'default'"
						@click="groupBySwitcher"
					/>
				</dx-item>
				<dx-item location="before" name="groupPanel" />
				<dx-item location="before" name="searchPanel" />
				<dx-item location="after" name="showDeletedButton">
					<dx-button icon="trash" :text="showDeleted ? 'Show deleted' : 'Show normal'" hint="Show deleted applicants" @click="showDeletedApplicants" :type="showDeleted ? 'default' : 'normal'" />
				</dx-item>
				<dx-item location="after" name="refreshButton">
					<dx-button icon="refresh" hint="Refresh data" @click="refreshButton" />
				</dx-item>
				<dx-item location="after" name="exportButton" />
				<dx-item location="after" name="columnChooserButton" />
			</dx-toolbar>
			<dx-scrolling :useNative="true" />
			<dx-export :enabled="true" :formats="['xlsx', 'pdf']" />
			<dx-editing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="form" :texts="{ confirmDeleteMessage: 'Are you sure you want to delete this record?<br>You can view and restore deleted items using the SHOW button at the top right.' }" />
			<dx-grouping :context-menu-enabled="false" expand-mode="rowClick" :autoExpandAll="false" />
			<dx-group-panel :visible="true" :allow-column-dragging="false" />
			<dx-master-detail :enabled="true" template="detail" />

			<template #detail="{ data }">
				<applicant-detail :applicant="data.data" @triggerEdit="startEditing(data)" @triggerDelete="startDeleting(data)" @triggerRestore="startRestoring(data)" />
			</template>

			<template #facebookLinkTemplate="{ data }">
				<span>{{ data.value }}</span>
				<span v-if="facebookIdRegex.test(String(data.value).trim())">
					<a :href="'https://facebook.com/' + data.value" target="_blank"><dx-button height="18px" icon="link" /></a>
				</span>
			</template>

			<dx-column data-field="id" data-type="number" caption="AppID" :width="90" :allow-header-filtering="false" :allow-editing="false" />
			<dx-column data-field="updated_at" data-type="datetime" caption="Last changed" sortOrder="desc" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="branch" caption="Branch" :width="150" :allow-header-filtering="true" :group-index="groupBySchool ? 1 : undefined" />
			<dx-column data-field="school" caption="School" :width="150" :allow-header-filtering="true" :group-index="groupBySchool ? 1 : undefined">
				<dx-lookup :data-source="lookupSchool" value-expr="slug" display-expr="title" />
			</dx-column>
			<dx-column data-field="course" caption="Course" :width="150" :allow-header-filtering="true">
				<dx-lookup :data-source="lookupCourse" value-expr="code" display-expr="title" />
			</dx-column>
			<dx-column data-field="review_program" caption="Program" :width="150" :allow-header-filtering="true">
				<dx-lookup :data-source="lookupProgram" value-expr="code" display-expr="title" />
			</dx-column>
			<dx-column data-field="board_exam_date" caption="Board exam date" data-type="date" :width="150" :allow-header-filtering="false" />
			<dx-column caption="Name">
				<dx-column data-field="first_name" caption="First" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="middle_name" caption="Middle" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="last_name" caption="Last" :width="100" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="mobile" caption="Mobile" :width="130" :allow-header-filtering="false" />
			<dx-column data-field="email" caption="Email" :width="190" :allow-header-filtering="false" />
			<dx-column data-field="facebook" caption="Facebook Messenger" :width="190" cell-template="facebookLinkTemplate" :allow-header-filtering="false" />
			<dx-column data-field="address" caption="Address" :width="250" :allow-header-filtering="false" />
			<dx-column caption="Emergency Contact">
				<dx-column data-field="emergency_phone" caption="E. Phone" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="emergency_name" caption="E. Name" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="emergency_relationship" caption="Relation" :width="130" :allow-header-filtering="true" />
				<dx-column data-field="emergency_address" caption="E. Address" :width="130" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="created_at" data-type="datetime" caption="Submitted at" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="user_id" caption="User Author" :width="120" :allow-header-filtering="false" :allow-editing="false" />
			<dx-column data-field="survey_id" caption="Survey ID" :width="120" :allow-header-filtering="false" :allow-editing="false" />
		</dx-data-grid>
	</div>
</template>

<script setup>
/* eslint-disable */
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid, { DxColumn, DxFilterRow, DxPager, DxPaging, DxColumnChooser, DxPosition, DxStateStoring, DxToolbar, DxItem, DxSearchPanel,
	DxScrolling, DxHeaderFilter, DxExport, DxEditing, DxGrouping, DxGroupPanel, DxMasterDetail, DxLookup
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { inject, ref } from "vue";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import devices from "devextreme/core/devices"
import applicantDetail from "../components/applicant-detail";
import { confirm as DxConfirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

const datagridComponent = ref(null);			// Template ref for our main datagrid.
const $http = inject('$http');
const dataSourceConfig = new CustomStore({
	key: 'id',
	loadMode: 'raw',
	remoteOperations: false,
	load: () => {
		return Promise.all([
			$http.get('/application?only_deleted=' + (showDeleted.value ? 1 : 0)),
			$http.get('/user/mapped?key=user'),
		]).then(responses => {
			for (var datum of responses[0].data) {
				let mapRow = datum.user_id in responses[1].data ? responses[1].data[datum.user_id] : null;
				datum.survey_id = mapRow ? mapRow.survey_id : null;
			}
			return responses[0].data;
		});
	},
	update: (key, values) => {
		return dataSourceConfig.byKey(key).then(currentRecord => {
			// Merge the changes with the current record, and PUT the complete record.
			const updatedRecord = { ...currentRecord, ...values };
			// Normally attachments would need to be specified, but we pass a special flag to ignore all attachment changes.
			updatedRecord['_ignore_attachments'] = true;
			return $http.put(`/application/${key}`, updatedRecord).then(response => {
				if (response.status !== 200) {
					throw new Error('Failed to update application record (error code ' + response.status + ')');
				}
				return response.data;
			});
		}).catch(error => {
			console.error('Error updating application record:', error);
			throw error;
		});
	},
	remove: (key) => {
		return $http.delete(`/application/${key}`).then(response => {
			if (response.status !== 200) {
				throw new Error('Failed to delete application record (error code ' + response.status + ')');
			}
			return response.data;
		}).catch(error => {
			console.error('Error deleting application record:', error);
			throw error;
		});
	}
});

const onExporting = (e) => {
	let dateCode = (new Date()).toISOString().substring(0, 10);
	if (e.format == 'xlsx') {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');
		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			customizeCell: function(options) {
				options.excelCell.font = { name: 'Arial', size: 12 };
				options.excelCell.alignment = { horizontal: 'left' };
			} 
		}).then(function() {
			workbook.xlsx.writeBuffer()
				.then(function(buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Applicants-' + dateCode + '.xlsx');
				});
		});
	} else if (e.format == 'pdf') {
		const doc = new jsPDF();
		exportDataGridToPdf({
			jsPDFDocument: doc,
			component: e.component
		}).then(() => {
			doc.save('Applicants-' + dateCode + '.pdf');
		});
	}
};

// Allow toggle button for automatically switching to group-by school column - don't allow other grouping selections.
const groupBySchool = ref(false);
const groupBySwitcher = () => {
	groupBySchool.value = !groupBySchool.value;
}

// Use 10 rows by default for phones instead of 15. 
const defaultPagerSize = devices.current().deviceType == 'phone' ? 10 : 15;

// Regex to detect valid facebook ID's where we can show a link.
const facebookIdRegex = /^[a-z0-9\\.]+$/i;

// Refresh datagrid via internal DevExtreme methods.
const refreshButton = () => {
	datagridComponent.value.instance.refresh();
}

const lookupProgram = {
	key: 'code',
	loadMode: 'raw',
	load: () => {
		return $http.get('/setting?group=programs').then(response => response.data)
		.catch(error => {
			console.error('Error loading programs:', error);
			return [];
		});
	},
};
const lookupCourse = {
	key: 'code',
	loadMode: 'raw',
	load: () => {
		return $http.get('/setting?group=courses').then(response => response.data)
		.catch(error => {
			console.error('Error loading courses:', error);
			return [];
		});
	},
};

const lookupSchool = {
	key: 'slug',
	loadMode: 'raw',
	sort: 'title',
	pageSize: 10,
	paginate: true,
	load: () => {
		return $http.get('/setting?group=schools').then(response => response.data)
		.catch(error => {
			console.error('Error loading schools:', error);
			return [];
		});
	},
};

const startEditing = (data) => {
	datagridComponent.value.instance.collapseRow(data.key);
	datagridComponent.value.instance.editRow(data.rowIndex);
};

const startDeleting = (data) => {
	datagridComponent.value.instance.collapseRow(data.key);
	datagridComponent.value.instance.deleteRow(data.rowIndex);
};

const showDeleted = ref(false);
const showDeletedApplicants = () => {
	showDeleted.value = !showDeleted.value;
	datagridComponent.value.instance.refresh();
}

const startRestoring = (data) => {
	DxConfirm("Are you sure you want to un-delete this application?", "Confirm application restore").then(result => {
		if (!result)
			return;
		datagridComponent.value.instance.collapseRow(data.key);
		$http.post(`/application/${data.data.id}/restore`).then(response => {
			if (response.status !== 200) {
				throw new Error('Failed to restore application record (error code ' + response.status + ')');
			}
			notify({ message: "Application restored successfully!", position: 'top right' }, "success", 3000);
			datagridComponent.value.instance.refresh();
		}).catch(error => {
			console.error('Error restoring application record:', error);
		});
	});
}

</script>

<style>
.fieldName {
	display: inline-block;
	width: 100px;
	margin: 0.5em 0;
	font-weight: bold;
}
</style>