<template>
	<div>
		<h2 class="content-block">Settings Management</h2>
		<div class="content-block" style="margin-top:-15px;">⚠️ <span style="font-style:italic;">Caution - only applies to Application Form and Management pages.</span></div>
		<div class="settings-grid">
			<div class="settings-column">
				<div class="settings-item">
					<h3>Branches</h3>
					<dx-data-grid :data-source="branches" :search-panel="{ visible: true }"  height="400"
						:columns="[
							{ dataField: 'title', caption: 'Branch Name', sortOrder: 'asc' },
							{ dataField: 'region', caption: 'Region' }
						]"
					/>
				</div>
				<div class="settings-item">
					<h3>Courses</h3>
					<dx-data-grid :data-source="courses" :search-panel="{ visible: true }" height="400"
						:columns="[
							{ dataField: 'title', caption: 'Course Name', sortOrder: 'asc' }
						]"
					/>
				</div>
			</div>
			
			<div class="settings-column">
				<div class="settings-item">
					<h3>Schools</h3>
					<dx-data-grid :data-source="schools" :search-panel="{ visible: true }" height="400" :editing="{ mode: 'row', allowAdding: true, allowDeleting: true, texts: { confirmDeleteMessage: 'This school will be removed from all dropdowns. Continue?' } }"
						:columns="[
							{ dataField: 'title', caption: 'School Name', sortOrder: 'asc' },
							{ dataField: 'region', caption: 'Region' },
						]"
					/>
				</div>
				<div class="settings-item">
					<h3>Programs</h3>
					<dx-data-grid :data-source="programs" :search-panel="{ visible: true }" height="400"
						:columns="[
							{ dataField: 'title', caption: 'Program Name' },
							{ dataField: 'group', caption: 'Group', sortOrder: 'asc' }
						]"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable */
import DxDataGrid from 'devextreme-vue/data-grid';
import DataSource from "devextreme/data/data_source";
import slugify from 'slugify';
import { inject, ref } from "vue";
import { alert as dxAlert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

const $http = inject('$http');
const branches = new DataSource({
	key: 'code',
	load: () => {
		return $http.get('/setting?group=branches').then(response => response.data)
		.catch(error => {
			console.error('Error loading branches:', error);
			notify({ message: "Error loading branches: " + error.message, type: "error", position: "top" });
			return [];
		});
	}
});
const courses = new DataSource({
	key: 'code',
	load: () => {
		return $http.get('/setting?group=courses').then(response => response.data)
		.catch(error => {
			console.error('Error loading courses:', error);
			notify({ message: "Error loading courses: " + error.message, type: "error", position: "top" });
			return [];
		});
	}
});
const schools = new DataSource({
	key: 'slug',
	load: () => {
		return $http.get('/setting?group=schools').then(response => response.data)
		.catch(error => {
			console.error('Error loading schools:', error);
			notify({ message: "Error loading schools: " + error.message, type: "error", position: "top" });
			return [];
		});
	},
	insert: (values) => {
		const slug = slugify(values.title, { replacement: '-', lower: true, strict: true, trim: true });
		if (slug.length > 120)
			slug = slug.substring(0, 120);
		if (schools.items().find(s => s.slug == slug)) {
			console.error('School already exists:', slug);
			notify({ message: "School already exists: " + slug, type: "error", position: "top" });
			return [];
		}
		if (!values.region || !values.title) {
			console.error('Region and title are required');
			notify({ message: "Region and title are required", type: "error", position: "top" });
			return [];
		}

		const data = {
			"location": "",
            "title": values.title,
            "slug": slug,
            "region": values.region,
            "courses": [
                "*"
            ]
		};
		return $http.post('/setting', { group: 'schools', key: slug, value: data }).then(response => response.data)
		.catch(error => {
			console.error('Error adding school:', error);
			notify({ message: "Error adding school: " + error.message, type: "error", position: "top" });
			return [];
		});
	},
	remove: (key) => {
		return $http.get('/setting/schools/' + key + '?references=1').then(lookup => {
			if (lookup.data['_references'] || lookup.data['_references'] > 0) {
				dxAlert("This school is being used in one or more applications. Please change the school in the applications management page first.", "Error - school not deleted");
				return [];
			}
			return $http.delete('/setting/' + lookup.data.id).then(response => response.data)
		})
		.catch(error => {
			console.error('Error removing school:', error);
			notify({ message: "Error removing school: " + error.message, type: "error", position: "top" });
			return [];
		});
	}

});
const programs = new DataSource({
	key: 'code',
	load: () => {
		return $http.get('/setting?group=programs').then(response => response.data)
		.catch(error => {
			console.error('Error loading programs:', error);
			notify({ message: "Error loading programs: " + error.message, type: "error", position: "top" });
			return [];
		});
	}
});


</script>

<style scoped>
.settings-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	margin-top: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
}

.settings-column {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.settings-item {
	background: #fff;
	padding: 1rem;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-item h3 {
	margin-top: 0;
	margin-bottom: 1rem;
	color: #333;
	font-size: 1.5rem;
}
</style>
