import auth from "./auth";

export default [
	{
		text: "Dashboard",
		path: "/home",
		icon: "home"
	},
	{
		text: "Application Form",
		path: "/application",
		icon: "product"
	},
	{
		text: "Survey Form",
		path: "/survey",
		icon: "gift"
	},
	{
		text: "Alumni Form",
		path: "/alumni",
		icon: "card",
		visible: auth.isStaff.value,
	},
	{
		text: "Management",
		icon: "folder",
		visible: auth.isStaff.value,
		items: [
			{
				text: "Settings",
				path: "/manage/settings",
			},
			{
				text: "Applicants",
				path: "/manage/applicants"
			},
			{
				text: "Surveys",
				path: "/manage/surveys"
			},
			{
				text: "Employees",
				path: "/manage/employees"
			}
		]
	}
];